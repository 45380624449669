import { PureComponent } from 'react';

import Pagination from 'Component/Pagination';
import { ReactElement } from 'Type/Common.type';

import Table from '../Table';
import ContractorStructureCreate from '../ContractorStructureCreate';
import ContractorStructureUpdate from '../ContractorStructureUpdate';
import { ContractorStructuresListComponentProps } from './ContractorStructuresList.type';
import { StructureOutput } from '../../query/Contractors/Contractors.type';

import './ContractorStructuresList.style';
import ContractorStructureDelete from '../ContractorStructureDelete';

/** @namespace Pwabb/Component/ContractorStructuresList/Component */
export class ContractorStructuresList extends PureComponent<ContractorStructuresListComponentProps> {
    renderPagination() {
        const { isLoading, pageInfo } = this.props;
        const { total_pages: totalPages } = pageInfo;

        return <Pagination isLoading={isLoading} totalPages={totalPages} />;
    }

    renderRowActions(structure: StructureOutput): ReactElement {
        const { onStructureDeleted, onStructureUpdated } = this.props;

        return (
            <div block="ContractorStructuresList" elem="RowActions">
                <ContractorStructureUpdate structure={structure} onUpdated={onStructureUpdated} />
                <ContractorStructureDelete structure={structure} onDeleted={onStructureDeleted} />
            </div>
        )
    }

    renderTableActions(): ReactElement {
        const { onStructureCreated } = this.props;

        return (
            <div block="ContractorStructuresList" elem="TableActions">
                <ContractorStructureCreate onCreated={onStructureCreated} />
            </div>
        )
    }

    renderTable() {
        const {
            activeRows,
            data,
            isLoading,
            onRowClick,
        } = this.props;


        return (
            <Table
                data={data}
                isLoading={isLoading}
                onClick={onRowClick}
                row={{
                    onClick: onRowClick,
                    mix: (user = {}) => {
                        if (!user) {
                            return {};
                        }

                        return { block: 'ContractorStructuresList', elem: 'Row', mods: { isAdmin: false } };
                    },
                }}
                columns={[
                    {
                        key: 'name',
                        label: __('Name'),
                    },
                    {
                        key: 'address',
                        label: __('Address'),
                        component: (_: any, structure: StructureOutput) =>
                            <span>{structure.city || '---'}</span>

                    },
                    {
                        key: 'users',
                        label: __('Users'),
                        component: (_: any, structure: StructureOutput & { users: String[] }) => {
                            return <span>Quantity: {structure.users.length}</span>
                        }
                    },
                    {
                        key: 'actions',
                        label: '',
                        mix: { block: 'ContractorStructuresList', elem: 'TableCell', mods: { actions: true } },
                        component: (_: any, structure: StructureOutput) => {
                            return this.renderRowActions(structure)
                        }
                    }
                ]}
                renderActiveRow={(data: StructureOutput & { users: string[] }) => {
                    return (
                        <>
                            <td />
                            <td>
                                <span>{data.name}</span>
                                <span>{data.street} </span>
                                <span>{data.postcode}</span>
                                <span>{data.city}</span>
                                <span>{data.province}</span>
                                <span>{data.country}</span>
                            </td>
                            <td>
                                {data.users.map((user) => <span>{user}</span>)}
                            </td>
                            <td />
                        </>
                    );
                }}
                activeRows={activeRows}
                enableChevron
            />
        );
    }

    renderMobileContent() {
        const {
            activeRows,
            data,
            onRowClick,
        } = this.props;

        return data.map((structure) => {
            const { city, name } = structure;

            const isActive = activeRows.includes(structure.id);

            return (
                <div block="ContractorStructuresList" elem="UserList" mods={{ isActive }} onClick={() => onRowClick(structure)}>
                    {this.renderRowActions(structure)}
                    <p>
                        <span>{__('Department name')}: </span>
                        {name}
                    </p>
                    <p>
                        <span>{__('Address')}: </span>
                        {city || '---'}
                    </p>
                    {isActive && (
                        <div block="ContractorStructuresList" elem="UserListDetails">
                            <p>
                                <div>{structure.name}</div>
                                <div>{structure.street} </div>
                                <div>{structure.postcode}</div>
                                <div>{structure.city}</div>
                                <div>{structure.province}</div>
                                <div>{structure.country}</div>
                            </p>
                        </div>
                    )}
                    <p>
                        <span>{__('Users')}: </span>
                        {__('Quantity')}: {structure.users.length}
                    </p>
                    {isActive && (
                        <div block="ContractorStructuresList" elem="UserListDetails">
                            <p>
                                {structure.users.map((user) => <div>{user}</div>)}
                            </p>
                        </div>
                    )}
                </div>
            );
        });
    }

    renderMainOffice(): ReactElement {
        const { contractorForLoggedInUser } = this.props;

        const row = (key: string, value: string) =>
            <tr>
                <td>{key}</td>
                <td>{value}</td>
            </tr>

        return (
            <div block="ContractorStructuresMainOffice">
                <div block="ContractorStructuresMainOffice" elem="Header">
                    <h3>{__('Main office')}</h3>
                </div>
                <table block="Table" elem="Table">
                    <tbody>
                        {row(__('VAT ID'), contractorForLoggedInUser?.vat_id || '')}
                        {row(__('Company code'), contractorForLoggedInUser?.regon || '')}
                        {row(__('Company name'), contractorForLoggedInUser?.company || '')}
                        {row(__('Street and number'), contractorForLoggedInUser?.street || '')}
                        {row(__('Postcode'), contractorForLoggedInUser?.postcode || '')}
                        {row(__('City'), contractorForLoggedInUser?.city || '')}
                        {row(__('Country'), contractorForLoggedInUser?.country || '')}
                    </tbody>
                </table>
            </div>
        )
    }

    renderBranch(): ReactElement {
        const { device } = this.props;

        return (
            <div block="ContractorStructuresList">
                <div block="ContractorStructuresList" elem="Header">
                    <h3>{__('Branch')}</h3>
                    {this.renderTableActions()}
                </div>
                {device.isMobile ? this.renderMobileContent() : this.renderTable()}
                {this.renderPagination()}
            </div>
        )
    }

    render() {
        return (
            <div block="ContractorStructuresLayout">
                {this.renderMainOffice()}
                {this.renderBranch()}
            </div>
        );
    }
}

export default ContractorStructuresList;
