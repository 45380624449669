import KeyValueTable from 'Component/KeyValueTable';
import { DataPair } from 'Component/KeyValueTable/KeyValueTable.type';
import { ReactElement } from 'Type/Common.type';

import { MyAccountContractorTableComponentProps } from './MyAccountContractorTable.type';

/** @namespace PlugAndSell2/Component/MyAccountContractorTable/Component */
export class MyAccountCustomerTableComponent extends KeyValueTable<MyAccountContractorTableComponentProps> {
    // @ts-ignore
    dataPairArray(): DataPair[] {
        const { contractor } = this.props;

        return [
            {
                key: 'vat_id',
                label: __('VAT Number'),
                source: contractor,
            },
            {
                key: 'regon',
                label: __('Company code'),
                source: contractor,
            },
            {
                key: 'company',
                label: __('Company name'),
                source: contractor,
            },
            {
                key: 'street',
                label: __('Street address'),
                source: contractor,
            },
            {
                key: 'postcode',
                label: __('Postcode'),
                source: contractor,
            },
            {
                key: 'city',
                label: __('City'),
                source: contractor,
            },
            {
                key: 'country',
                label: __('Country'),
                source: contractor,
            },
        ];
    }

    render(): ReactElement {
        return <div block="MyAccountContractorTable">{this.renderTable()}</div>;
    }
}

export default MyAccountCustomerTableComponent;
