import CreateB2BLink from "../components/CreateB2BLink";
import { MyAccountPageState } from "Component/MyAccountOverlay/MyAccountOverlay.config";
import { CONFIRMATION_REQUIRED } from "Component/MyAccountCreateAccount/MyAccountCreateAccount.config";
import history from 'Util/History';
import transformToNameValuePair from "Util/Form/Transform";
import { FieldData } from "Util/Form/Form.type";
import { NotificationType } from 'Store/Notification/Notification.type';
import getStore from "Util/Store";
import MyAccountDispatcher from "../store/MyAccount/MyAccount.dispatcher";
import { CreateAccountB2BOptions } from "../store/MyAccount/MyAccount.type";
import Field from "Component/Field";
import { FieldType } from "Component/Field/Field.config";
import Form from "Component/Form";
import CreateB2BFields from "../components/CreateB2BFields";

export const MyAccountCreateAccount_renderAdditionalField = (args: any, callback: Function, instance: any) => {
  const { isB2B } = instance.props;

  return (
    <>
        {callback.apply(instance, args)}
        {!isB2B && <CreateB2BLink />}
    </>
  )
}

export const MyAccountCreateAccount_renderCompanyField = (args: any, callback: Function, instance: any) => {
  const { isB2B } = instance.props;

  if (!isB2B) {
      return;
  }

  return callback(...args);
}

export const MyAccountCreateAccount_renderVatNumberField = (_args: any, _callback: Function, instance: any) => {
  const { isB2B } = instance.props;

  if (!isB2B) {
      return null;
  }

  return (
      <Field
          type={FieldType.TEXT}
          label={__('Tax/VAT Number')}
          attr={{
              id: 'taxvat',
              name: 'taxvat',
              placeholder: __('Your Tax/VAT Number'),
          }}
          validateOn={['onChange']}
          validationRule={{
              isRequired: true,
          }}
          addRequiredTag
      />
  );
}

export const MyAccountCreateAccount_renderCreateAccountForm = (_: any, callback: Function, instance: any) => {
  const { isB2B, onError, onSuccess } = instance.props;

  return (
      <Form key="create-account" onSubmit={onSuccess} onError={onError}>
          {instance.renderCreateAccountPersonalInfoFields()}
          {isB2B && <CreateB2BFields.CreateAccountAddressFields countriesOptions={instance.props.countriesOptions} />}
          {instance.renderCreateAccountSignUpInfoFields()}
          {instance.renderNewsletterConsents()}
          {instance.renderRegisterConsents()}
          {instance.renderSubmitButton()}
      </Form>
  );
}

export const MyAccountCreateAccount_onSuccess = async (args: [form: HTMLFormElement, fields: FieldData[]], callback: Function, instance: any): Promise<void> => {
  const { onSignIn, setSignInState, setLoadingState, isLoading, isLandingPage, showNotification, isMobile, isB2B } = instance.props;

  if (!isB2B) {
    return callback(...args);
  }

  const [_form, fields] = args;

  // const consents = Consents.getAcceptedConsents(fields);
  const { password, email, firstname, lastname, city, country, company, region, comment, taxvat, company_code, ...other } =
      transformToNameValuePair<Record<string, string | number | boolean>>(fields);

  const customerDataB2B = {
    customerInput: {
      email,
      firstname,
      lastname,
      password
    },
    contractorInput: {
      city,
      country,
      company,
      phone: other['phone-number'],
      postcode: other['postal-code'],
      region: region || '',
      street: `${other['street-address']} ${other['street-address-2']} ${other['street-address-3']}`,
      vat_id: taxvat,
      regon: company_code,
      attachments: [],
      comment: comment || ''
    }
  } as CreateAccountB2BOptions

  if (isLoading) {
      return;
  }

  try {
      const code = await MyAccountDispatcher.createAccountB2B(customerDataB2B, getStore().dispatch)

      // if user needs confirmation
      if (code === CONFIRMATION_REQUIRED) {
          setSignInState(MyAccountPageState.STATE_CONFIRM_EMAIL);

          if (isLandingPage || isMobile) {
              showNotification(
                  NotificationType.SUCCESS,
                  // eslint-disable-next-line max-len
                  __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
              );
              history.push('/default/customer/account/login');
          }
      } else if (code !== false) {
          onSignIn();
      }
  } finally {
      setLoadingState(false);
  }
}

export default {
  'PlugAndSell2/Component/MyAccountCreateAccount/Component': {
    'member-function': {
      renderAdditionalField: MyAccountCreateAccount_renderAdditionalField,
      renderCompanyField: MyAccountCreateAccount_renderCompanyField,
      renderCreateAccountForm: MyAccountCreateAccount_renderCreateAccountForm,
      renderVatNumberField: MyAccountCreateAccount_renderVatNumberField,
    }
  },
  'PlugAndSell2/Component/MyAccountCreateAccount/Container': {
    'member-function': {
      onSuccess: MyAccountCreateAccount_onSuccess,
    }
  },
  'PlugAndSell2/Store/MyAccount/Dispatcher': {
    'member-function': {

    }
  }
};
